<div class="row" style="margin-left: 1rem; margin-right: 1.5rem">
  <div class="col-md-6 col-lg-6">
    <h1>CECH</h1>
    <p class="text-justify">
      <!-- {{comite.descripcion}} -->
      La Sentencia constituyó el Consejo Estratégico de la Cuenca Hidrográfica
      del Río Bogotá – CECH  de manera transitoria hasta tanto se expida la ley
      de creación de la Gerencia de la Cuenca Hidrográfica del Río Bogotá – GCH
      –. El propósito del Consejo radica en dirigir, gestionar, articular,
      integrar y coordinar con un enfoque sistémico la cuenca hidrográfica del
      Río Bogotá. El
    </p>
    <br />
    <h4>Funciones</h4>
    <br />
    <p class="text-justify">
      <i class="material-icons">check</i> Coordinar, cooperar y gestionar con
      los entes territoriales y las autoridades ambientales la implementación y
      seguimiento de la Política Ambiental.
    </p>
    <p class="text-justify">
      <i class="material-icons">check</i> Incrementar los mecanismos de
      coordinación y cooperación interinstitucional e intersectorial, y evaluar
      periódicamente su gestión permitiendo el fortalecimiento de las
      instituciones encargadas de la gestión ambiental.
    </p>
     
    <p class="text-justify">
      <i class="material-icons">check</i> Establecer directrices y
      procedimientos para la articulación de acciones entre las Corporaciones
      Autónomas Regionales y las entidades territoriales, para el manejo y
      financiación de proyectos comunes. 
    </p>
    <p class="text-justify">
      <i class="material-icons">check</i> Desarrollar acciones para la
      articulación entre las Autoridades del orden Nacional, Regional,
      Departamental, Distrital y Municipal.
    </p>
    <p class="text-justify">
      <i class="material-icons">check</i> Desarrollar acciones de articulación y
      coordinación para la planificación ambiental territorial entre las
      diferentes instituciones con competencia en el tema.
    </p>
    <p class="text-justify">
      <i class="material-icons">check</i> Establecer directrices e implementar
      programas para la cooperación regional entre las autoridades ambientales y
      los entes territoriales para la formulación, estructuración, desarrollo,
      ejecución y puesta en marcha de las estrategias, planes, programas,
      proyectos y, en general, todas las actividades necesarias para la gestión
      integral de la cuenca hidrográfica del Río Bogotá. 
    </p>
    <p class="text-justify">
      <i class="material-icons">check</i> Dirimir las diferentes problemáticas
      del orden técnico, administrativo y financiero en torno al recurso hídrico
      en la cuenca hidrográfica del Río Bogotá. 
    </p>
    <p class="text-justify">
      <i class="material-icons">check</i> Realizar el monitoreo, evaluación y
      seguimiento a la gestión adelantada de manera integral para la
      recuperación y sostenibilidad de la cuenca del Río Bogotá.
    </p>
  </div>

  <div class="col-md-5 col-lg-5 offset-md-1 offset-lg-1">
    <h1>Listado de miembros</h1>
    <ul class="list-group list-group-flush">
      <!--<li class="list-group-item" *ngFor="let miembro of miembros">{{miembro.primerNombre}} {{miembro.primerApellido}}
        ({{miembro.correo}})</li>-->
      <li class="text-justify">
        <p>
          <i class="material-icons">check</i> Ministerio de Ambiente y
          Desarrollo Sostenible - Preside y realiza el Director de Recurso
          Hídrico - Fabián Mauricio Caicedo.
        </p>
      </li>
      <li class="text-justify">
        <p>
          <i class="material-icons">check</i> Empresa de Acueducto de Bogotá -
          EAB, representada por el Director Red Troncal de Alcantarillado (E) -
          Yamid García Zúñiga.
        </p>
      </li>
      <li class="text-justify">
        <p>
          <i class="material-icons">check</i> Secretaría Distrital de Ambiente,
          representada por el Director Legal Ambiental: Cristian Alonso Carabaly
          Cerra
        </p>
      </li>
      <li class="text-justify">
        <p>
          <i class="material-icons">check</i> Gobernación de Cundinamarca,
          representada por la Secretaria de Ambiente: Nidia Clemencia Riaño
        </p>
      </li>
      <li class="text-justify">
        <p>
          <i class="material-icons">check</i> Corporación Autónoma Regional de
          Cundinamarca - CAR, representado por su Director General: Luis
          Fernando Sanabria Martínez.
        </p>
      </li>
      <li class="text-justify">
        <p>
          <i class="material-icons">check</i> Entes territoriales: Municipio de
          Cajicá: Alcalde Fabio Hernán Ramírez Rodríguez, Municipio la Mesa:
          Alcalde Cornelio Humberto Segura Barragán .
        </p>
      </li>
    </ul>

    <br />

    <h4>Estructura</h4>
    <div>
      <img src="../../../assets/temp/CECH.png" alt="CECH" class="img-fluid" />
    </div>
    <br />
    <p>
      <a routerLink="/cech/documentos">Ver documentos</a>
    </p>
  </div>
</div>
