<div style="margin-left: 0.5rem; margin-right: 0.5rem">
  <h3>Administrador de Información</h3>
  <br />
  <div>
    <mat-form-field>
      <mat-select
        placeholder="Filtrar por estado"
        [(ngModel)]="selectedFilterEstado"
        multiple
      >
        <mat-option *ngFor="let estado of estados" [value]="estado.idEstado">
          {{ estado.nombre }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="table-responsive">
	<ul class="pagination">
		<pagination-controls (pageChange)="p = $event"  previousLabel="Anterior"
		nextLabel="Siguiente"></pagination-controls>
	  </ul>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Fecha</th>
          <th scope="col">Autor</th>
          <th scope="col">Documento</th>
          <th scope="col">Descripción</th>
          <th scope="col">Estado</th>
          <th scope="col">Previsualizar</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let documento of documentos | paginate: { itemsPerPage: 20, currentPage: p }">
          <td width="10%">{{ documento.fecha | date }}</td>
          <td width="15%">
            {{ documento.idUsuarioNavigation.primerNombre }}
            {{ documento.idUsuarioNavigation.primerApellido }}
          </td>
          <td>{{ documento.nombreDocumento }}</td>
          <td width="40%">{{ documento.descripcionDocumento }}</td>
          <td>
            <div
              *ngIf="
                ![23, 87, 125, 126, 127, 128, 129, 130].includes(
                  documento.idMicrositio
                )
              "
              class="btn-group-toggle"
              style="width: 9rem"
              data-toggle="buttons"
            >
              <label
                [class]="
                  documento.aprobado ? 'btn btn-success' : 'btn btn-secondary'
                "
              >
                <input
                  type="checkbox"
                  autocomplete="off"
                  (change)="cambiarEstado(documento)"
                  [(ngModel)]="documento.aprobado"
                />
                {{ !documento.aprobado ? "No Aprobado" : "Aprobado" }}
              </label>
            </div>
            <div
              *ngIf="
                [23, 87, 125, 126, 127, 128, 129, 130].includes(
                  documento.idMicrositio
                )
              "
              class="btn-group-toggle"
              style="width: 10rem"
              data-toggle="buttons"
            >
              <label
                [class]="
                  documento.importando
                    ? 'btn btn-primary'
                    : documento.aprobado
                    ? 'btn btn-success'
                    : 'btn btn-secondary'
                "
              >
                <input
                  type="checkbox"
                  autocomplete="off"
                  (change)="cambiarEstado(documento)"
                  [(ngModel)]="documento.aprobado"
                />
                {{
                  documento.importando
                    ? "Importando..."
                    : !documento.aprobado
                    ? "No Importado"
                    : "Importado"
                }}
              </label>
            </div>
          </td>
          <td>
            <a
              [href]="
                'https://docs.google.com/uc?id=' +
                documento.idDrive +
                '&export=view'
              "
              target="_blank"
            >
              <span>
                <img
                  src="../../assets/PNG/logo-pdfs.png"
				  width="40"
				  height="40"
                  alt=""
                />
              </span>
            </a>
          </td>
        </tr>
      </tbody>
	</table>
	<ul class="pagination">
		<pagination-controls (pageChange)="p = $event"  previousLabel="Anterior"
		nextLabel="Siguiente"></pagination-controls>
	  </ul>
  </div>
</div>
