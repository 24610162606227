<div class="container-fluid container-noticias">

  <h4>Consultar Noticias</h4>
  <!-- <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-default">Buscar</span>
        </div>
        <input type="text" class="form-control" aria-label="Default" [(ngModel)]="filtro" (input)="filtrar()" aria-describedby="inputGroup-sizing-default">
      </div> -->
  <div class="text-right">
    <button class="btn btn-success" (click)="agregarNuevo()">Agregar noticia</button>
  </div>
  <br>
  <ul class="pagination">
    <pagination-controls (pageChange)="p = $event"  previousLabel="Anterior"
    nextLabel="Siguiente"></pagination-controls>
  </ul>
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Titulo</th>
        <th scope="col">Descripcion</th>
        <th scope="col">Accion</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let noticia of noticias | paginate: { itemsPerPage: 6, currentPage: p }">
        <th scope="row">{{noticia.titulo}}</th>
        <td>{{noticia.descripcion.substring(0, 200) + '...'}}</td>
        <td><a routerLink="/noticias/editar/{{noticia.idNoticia}}">Editar</a></td>
      </tr>
    </tbody>
  </table>
  <ul class="pagination">
    <pagination-controls (pageChange)="p = $event" previousLabel="Anterior"
    nextLabel="Siguiente"></pagination-controls>
  </ul>
</div>