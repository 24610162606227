import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { OrdenService } from 'src/app/services/orden/orden.service';
import { Base } from "src/app/shared/base";
import moment from "moment";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent extends Base implements OnInit,OnDestroy {
  datos :any ;
  idPregunta : number;
  fecha :any;
  text : boolean ;
  number : boolean ;
  moneda : boolean ;
  fechadatos :boolean;
  select : boolean;
  opciones : [];


  constructor(
    private ordenService: OrdenService,
    private appService: AppService,
    public dialogref:MatDialogRef<ConfirmDialogComponent>,
    @Inject (MAT_DIALOG_DATA) public data:any) {
      super();
     }

  ngOnInit(): void {
    this.datos = this.data.dataKey;
    this.opciones =this.data.dataopci
    this.idPregunta = this.datos.idOpcionPregunta;
    this.fecha = moment(this.datos.fecha).utc().format('DD-MM-YYYY')
    if(this.datos.texto !== null){
      this.text = true;
    }else if (this.datos.moneda !== null){
      this.moneda = true;
    }else if (this.datos.numero !== null){
      this.number = true;
    }else if (this.datos.fecha !== null){
      this.fechadatos = true;
    }else if (this.datos.idOpcionPregunta !== null){
      this.select = true;
    }
  }
  onClick():void{
    this.dialogref.close(false);
  }
  RealizarUpdate():void{
      if(this.idPregunta === null){
      this.unsubscribeOndestroy(
      this.ordenService.putRespuestas(this.datos).subscribe(
        (res) =>{
          this.appService.success("Se realiza la actualización exitosamente.");
          this.dialogref.close(true);
        },
        (err) =>{
          this.dialogref.close(false);
          this.appService.error("No se puede realizar la actualización.")
        }
      )
    )
      }else {
        this.unsubscribeOndestroy(
          this.ordenService.putIdOpcionPregunta(this.datos.idRespuesta,this.datos.idOpcionPregunta).subscribe(
            (res) =>{
              this.appService.success("Se realiza la actualización exitosamente.");
              this.dialogref.close(true);
            },
            (err) =>{
              this.dialogref.close(false);
              this.appService.error("No se puede realizar la actualización.")
            }
          )
        )
          }
  }

  OnDestroy(){
    console.log("Hola")
  }

}
