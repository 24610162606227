<h3>Matriz Financiera</h3>

<mat-tab-group (selectedTabChange)="cambioDatos($event.index + 1)">
  <mat-tab *ngFor="let entidad of entidades" [label]="entidad.descripcion">
    <div class="card" *ngFor="let orden of ordenes; let i = index">
      <span
        *ngFor="let dato of orden"
        [class]="dato.valor ? 'card-content' : ''"
      >
        <span *ngIf="dato.valor" class="card-text">
          <p>{{ dato.idDetalleIndicadorNavigation.descripcion }}</p>
          <span>{{ dato.valor }}</span>
        </span>
      </span>
    </div>
  </mat-tab>
</mat-tab-group>
