<span *ngIf="acceso">
  <span style="margin-right: 2rem">
    <mat-form-field>
      <mat-select placeholder="seleccione una orden" [(ngModel)]="selectedOrden">
        <mat-option
        [value]="-1"
      >
        Todas las ordenes
      </mat-option>
        <mat-option *ngFor="let orden of ordenes" [value]="orden.idOrden">
          {{ orden.nombre }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="tipoUsuario === 1">
      <mat-select
        placeholder="seleccione un municipio"
        [(ngModel)]="selectedMunicipio"
      >
      <mat-option
      [value]="-1"
    >
      Todos los municipios
    </mat-option>
        <mat-option
          *ngFor="let municipio of municipios"
          [value]="municipio.idOpcion"
        >
          {{ municipio.descripcion }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="tipoUsuario === 1">
      <mat-select 
      placeholder="seleccione un semestre"
      [(ngModel)]="selectedSemestre"
      >
        <mat-option  [value]="-1"> Semestres reportados </mat-option>
        <mat-option 
        *ngFor="let semestre of semestres" 
        [value]="semestre.idOpcion"
        >
          {{ semestre.descripcion }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button
      (click)="consultar()"
      style="margin-left: 1rem"
      class="btn btn-default"
    >
      Consultar
    </button>
    <button *ngIf="respuestas && respuestas.length" (click)="generarExcel()" style="margin-left: 1rem" class="btn btn-default">
      Generar Excel
    </button>
    <a style="margin-left: 2rem;" routerLink="../grafico">Ver graficas de las respuestas</a>
  </span>
  <div>
    <mat-spinner *ngIf="cargando"></mat-spinner>
  </div>
  <div>
    <ul class="pagination">
      <pagination-controls (pageChange)="p = $event"  previousLabel="Anterior"
      nextLabel="Siguiente"></pagination-controls>
    </ul>
    <div [hidden]="!ver">
      <table class="table table-striped"   id="generarexcel">
        <thead>
          <tr>
            <th scope="col">Municipio</th>
            <th scope="col">Radicado </th>
            <th scope="col">Semestre </th>
            <th scope="col">Pregunta</th>
            <th scope="col">Respuesta</th>
            <th scope="col">Fecha de auditoria </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let resp of respuestaexcel">
            <td>{{ resp.municipio }}</td>
            <td>{{ resp.radicado }}</td>
            <td>{{ resp.semestre }}</td>
            <td>{{ resp.descripcion }}</td>
            <td>{{ resp.moneda | currency }}</td>
            <td>{{ resp.fechaAud }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Orden</th>
          <th scope="col">Pregunta</th>
          <th scope="col">Respuesta</th>
          <th scope="col">Fecha Respuesta</th>
          <th scope="col">Usuario Respuesta</th>
          <th scope="col">Cargar Archivo</th>
          <th scope="col">Acción</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let respuesta of respuestas | paginate: { itemsPerPage: 20, currentPage: p }">
          <td id="nombre">{{ respuesta.orden.nombre }}</td>
          <td id="description">{{ respuesta.idPreguntaNavigation.descripcion }}</td>
          <td id="texto" *ngIf="respuesta.texto && !respuesta.archivo">{{ respuesta.texto }}</td>
          <td id="archivo" *ngIf="respuesta.texto && respuesta.archivo"><a target="__blank" [href]="'https://drive.google.com/file/d/' + respuesta.archivo + '/view?usp=sharing'">{{ respuesta.texto }}</a></td>
          <td id="numero" *ngIf="respuesta.numero">{{ respuesta.numero }}</td>
          <td id="moneda" *ngIf="respuesta.moneda">{{ respuesta.moneda | currency }}</td>
          <td id="fecha" *ngIf="respuesta.fecha">{{ respuesta.fecha | date: 'dd/MM/yyyy' }}</td>
          <td id="opcPregunta" *ngIf="respuesta?.idOpcionPreguntaNavigation?.idOpcionNavigation?.descripcion">
            {{
            respuesta.idOpcionPreguntaNavigation.idOpcionNavigation.descripcion
            }}
          </td>
          <td
          id="empty"
            *ngIf="!respuesta?.idOpcionPreguntaNavigation?.idOpcionNavigation?.descripcion && !respuesta.moneda && !respuesta.numero && !respuesta.texto && !respuesta.fecha">
            N/A
          </td>
          <td id="fechaAud">{{ respuesta.fechaAud | date: 'dd/MM/yyyy hh:mm' }}</td>
          <td id="respuesta">{{ respuesta.usuario }}</td>
          <td id="idPreguntaNav">
            <input
            *ngIf="respuesta.idPreguntaNavigation.descripcion.indexOf('Se adjunta Resumen Ejecutivo de Actividades Realizadas') !== -1 && !respuesta.archivo"
              (change)="seleccionarArchivo($event, respuesta)" type="file" value="Cargar Archivo">
          </td>
          <td id="boton">
            <button class="btn btn-default" mat-button (click)="openDialog(respuesta.idRespuesta,respuesta.idPregunta,respuesta.idOpcionPregunta)">Editar
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    
    <ul class="pagination">
      <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente">
      </pagination-controls>
    </ul>
  </div>
  
</span>
