<div id="carousel">
  <figure id="spinner">
    <figure>
      <img src="../../../assets/PNG/Alcaldia.jpg" alt="Ministerio de Ambiente y Desarrollo Sostenible" width="587" height="100">
      <figcaption>Anton Ploom</figcaption>
    </figure>    
    <figure>
      <img src="../../../assets/PNG/Gobernacion.jpg" alt="Secretaría Distrital de Ambiente" width="587" height="100">
      <figcaption>Daria Elmakova</figcaption>
    </figure>
    <figure>
      <img src="../../../assets/PNG/Min_Ambiente.jpg" alt="Corporación Autónoma Regional de Cundinamarca" width="587" height="100">
      <figcaption>Margaret Rodchenkova</figcaption>
    </figure>   
    <figure>
      <img src="../../../assets/PNG/Orarbo.jpg" alt="Corporación Autónoma Regional de Cundinamarca" width="587" height="100">
      <figcaption>Margaret Rodchenkova</figcaption>
    </figure>
    <figure>
      <img src="../../../assets/PNG/CAR.JPG" alt="Gobernación de Cundinamarca" width="587" height="100">
      <figcaption>Annija Kopshtale</figcaption>
    </figure>
    <figure>
      <img src="../../../assets/PNG/Rio_Vivo.jpg" alt="Corporación Autónoma Regional de Cundinamarca" width="587" height="100">
      <figcaption>Margaret Rodchenkova</figcaption>
    </figure>
  </figure>
</div>