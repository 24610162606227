<!--xs - sm -->
<div class="d-block d-sm-none">
  <div class="row pie-pagina">
    <div class="col-6 texto-footer">
      <h5 class="text-left"><strong>Manténgase en contacto</strong></h5>
      <p class="text-justify ">
        Si desea contactarse con nosotros
        haga clic <a routerLink="contacto"><strong>AQUÍ</strong></a>
      </p>
    </div>
    <div class="col-6 vertical-divider texto-footer">
      <h5 class="text-left"><strong>Estamos ubicados en</strong></h5>
      <p class="text-justify">Av. Esperanza # 62-49 Costado Esfera Pisos 6 y 7 Bogotá - Colombia</p>
    </div>
    <div class="col-xs-12 col-sm-12 text-center">
      <h5 style="color: #6E6E6F;"><strong>Síguenos</strong></h5>
      <!-- <img src="../../assets/PNG/fb-logo.png" alt="" class="img-fluid img-redes-sociales">
      <img src="../../assets/PNG/twt-logo.png" alt="" class="img-fluid img-redes-sociales">
      <img src="../../assets/PNG/itgm-logo.png" alt="" class="img-fluid img-redes-sociales"> -->
      <a href="https://www.youtube.com/channel/UCm_5NC08s3EiSNLSPcGPueQ" target="_blank">
        <img src="../../assets/PNG/youtube-logo.png" alt="" class="img-fluid img-redes-sociales">
      </a>
    </div>
  </div>
  <div class="row justify-content-center text-center">
    <div class="col-12">
      <p style="font-size: 9px;">Última actualización: <strong>24/11/2019</strong></p>
    </div>
    <div class="col-12">
      <p style="font-size: 9px;">Visitantes: <strong>{{ cantidadUsuarios }}</strong></p>
    </div>
  </div>
</div>
<!--Fin xs - sm-->
<!--md-->
<div class="d-none d-md-block d-lg-none">
  <div class="row pie-pagina">
    <div class="col-6 texto-footer">
      <h5 class="text-center"><strong>Manténgase en contacto</strong></h5>
      <p class="text-center">
        Si desea contactarse con nosotros
        haga clic <a routerLink="contacto"><strong>AQUÍ</strong></a> para dirigirse a nuestro formulario de contacto.
      </p>
    </div>
    <div class="col-6 vertical-divider texto-footer">
      <h5 class="text-center"><strong>Estamos ubicados en</strong></h5>
      <p class="text-center">Av. Esperanza # 62-49 Costado Esfera Pisos 6 y 7 Bogotá - Colombia</p>
    </div>
    <div class="col-xs-12 col-sm-12 text-center">
      <h5 style="color: #6E6E6F;"><strong>Síguenos</strong></h5>
      <!-- <img src="../../assets/PNG/fb-logo.png" alt="" class="img-fluid img-redes-sociales">
      <img src="../../assets/PNG/twt-logo.png" alt="" class="img-fluid img-redes-sociales">
      <img src="../../assets/PNG/itgm-logo.png" alt="" class="img-fluid img-redes-sociales"> -->
      <a href="https://www.youtube.com/channel/UCm_5NC08s3EiSNLSPcGPueQ" target="_blank">
        <img src="../../assets/PNG/youtube-logo.png" alt="" class="img-fluid img-redes-sociales">
      </a>
    </div>
  </div>
  <div class="row justify-content-center text-center">
    <div class="col-12">
      <p style="font-size: 9px;">Última actualización: <strong>24/11/2019</strong></p>
    </div>
    <div class="col-12">
      <p style="font-size: 9px;">Visitantes: <strong>{{ cantidadUsuarios }}</strong></p>
    </div>
  </div>
</div>
<!--Fin md-->
<!--Resto de resoluciones-->
<div class="d-none d-lg-block">
  <div class="row pie-pagina">
    <div class="col-6 texto-footer">
      <h4 class="text-center"><strong>Manténgase en contacto</strong></h4>
      <p class="text-center">
        Si desea contactarse con nosotros
        haga clic <a routerLink="contacto"><strong>AQUÍ</strong></a> para dirigirse a nuestro formulario de contacto.
      </p>
    </div>
    <div class="col-6 vertical-divider texto-footer">
      <h4 class="text-center"><strong>Estamos ubicados en</strong></h4>
      <p class="text-center">Av. Esperanza # 62-49 Costado Esfera Pisos 6 y 7 Bogotá - Colombia</p>
    </div>
    <div class="col-xs-12 col-sm-12 text-center">
      <h5 style="color: #6E6E6F;"><strong>Síguenos</strong></h5>
      <!-- <img src="../../assets/PNG/fb-logo.png" alt="" class="img-fluid img-redes-sociales">
      <img src="../../assets/PNG/twt-logo.png" alt="" class="img-fluid img-redes-sociales">
      <img src="../../assets/PNG/itgm-logo.png" alt="" width="54px" height="40px" class="img-redes-sociales"> -->
      <a href="https://www.youtube.com/channel/UCm_5NC08s3EiSNLSPcGPueQ" target="_blank">
        <img src="../../assets/PNG/youtube-logo.png" alt="" class="img-fluid img-redes-sociales">
      </a>
    </div>
  </div>
  <div class="row justify-content-center text-center">
    <div class="col-12">
      <p style="font-size: 9px;">Última actualización: <strong>24/11/2019</strong></p>
    </div>
    <div class="col-12">
      <p style="font-size: 9px;">Visitantes: <strong>{{ cantidadUsuarios }}</strong></p>
    </div>
  </div>
</div>
<!--Fin resto resoluciones-->