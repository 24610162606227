﻿<form name="formUsuario" (ngSubmit)="guardar()" ngNativeValidate style="margin-left: 0.5rem; margin-right: 0.5rem;">
  <h4>Información de Usuario</h4>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <input type="text" required [class]="valido ? 'form-control' : 'form-control invalid'" minlength="5"
        maxlength="15" aria-label="Nombre de usuario" name="nombreUsuario" [(ngModel)]="usuario.usuario1"
        aria-describedby="usuario-default" placeholder="Usuario">
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <input type="password" required
        title="Minimo una letra minuscula, una letra mayuscula, un numero y un caracter especial"
        pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$" name="contrasena" minlength="5" maxlength="10"
        aria-label="Contrasena" [(ngModel)]="usuario.contrasena"
        [class]="valido ? 'form-control' : 'form-control invalid'" (change)="validar()" placeholder="Contraseña">
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <input type="password" required
        title="Minimo una letra minuscula, una letra mayuscula, un numero y un caracter especial"
        pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$" name="confirmar" minlength="5" maxlength="10"
        aria-label="Confirmar" [(ngModel)]="confirmacionContrasena"
        [class]="valido ? 'form-control' : 'form-control invalid'" (change)="validar()"
        placeholder="Confirmar Contraseña">
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 form-group">
      <label
        for="selectRol">{{ !ocultarRol ? 'Seleccione un Rol' : 'Trabaja en una entidad del CECH?' }}
      </label>
      <div *ngIf="ocultarRol" class="form-check">
        <app-switch (seleccion)="esTrabajador($event)" [modelo]="trabajaEntidad"></app-switch>
      </div>
      <select *ngIf="!ocultarRol" name="roles" id="selectRol" [(ngModel)]="rolUsuario" name="rolUsuario"
        class="form-control">
        <option [ngValue]="rol" *ngFor="let rol of roles">{{rol.nombre}}</option>
      </select>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 form-group" *ngIf="trabajaEntidad">
      <label for="select-entidad">Entidad en la que trabaja</label>
      <select name="entidades" id="select-entidad" [(ngModel)]="entidadUsuario" class="form-control">
        <option *ngFor="let entidad of entidades" [ngValue]="entidad.idEntidad">{{ entidad.descripcion }}</option>
      </select>
    </div>
  </div>
  <h4>Datos Basicos</h4>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <input type="text" required aria-label="Primer Nombre" minlength="3" maxlength="30" name="primerNombre"
        [(ngModel)]="usuario.primerNombre" class="form-control" placeholder="Primer Nombre">
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <input type="text" aria-label="Segundo Nombre" minlength="3" maxlength="30" name="segundoNombre"
        class="form-control" [(ngModel)]="usuario.segundoNombre" placeholder="Segundo Nombre">
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <input type="text" required aria-label="Primer Apellido" minlength="3" maxlength="30" name="primerApellido"
        class="form-control" [(ngModel)]="usuario.primerApellido" placeholder="Primer Apellido">
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <input type="text" aria-label="Segundo Apellido" minlength="3" maxlength="30" name="segundoApellido"
        class="form-control" [(ngModel)]="usuario.segundoApellido" placeholder="Segundo Apellido">
    </div>
  </div>
  <h4>Información de Contacto</h4>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <input type="email" name="email" required class="form-control" aria-label="Correo Electronico"
        aria-describedby="correo-default" [(ngModel)]="usuario.correo" placeholder="Correo Electrónico">
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <input type="tel" name="celular" maxlength="10" aria-label="Celular" class="form-control"
        [(ngModel)]="usuario.celular" placeholder="Celular">
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <input type="tel" name="telefono" maxlength="10" required aria-label="Telefono" class="form-control"
        [(ngModel)]="usuario.telefono" placeholder="Teléfono">
    </div>
  </div>

  <div class="row">
    <div class="col">
      <input type="submit" name="guardar" value="Guardar" class="btn btn-success mr-5" />
      <input type="reset" name="limpiar" value="Limpiar" class="btn btn-danger" />
    </div>
  </div>
</form>


<form>



</form>