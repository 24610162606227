<!--Sitios de interés-->
<!--xs-->
<div class="d-block d-sm-none">
	<div class="text-center">
		<a href="http://orarbo.gov.co/">
			<img src="../../assets/PNG/orarbo.png" alt="" class="img-size">
		</a>
		<a routerLink="/cech/general">
			<img src="../../assets/PNG/logo_cech.png" alt="" class="img-size" height="145px">
		</a>
	</div>
</div>
<!--Fin xs-->
<!--Otros Dispositivos-->
<div class="d-none d-sm-block">
	<div class="row text-center">
		<div class="col-sm-6 col-md-6 col-lg-6">
			<a href="http://orarbo.gov.co/">
				<img src="../../assets/PNG/orarbo.png" alt="" width="250px" height="110px">
			</a>
		</div>
		<div class="col-sm-6 col-md-6 col-lg-6">
			<a routerLink="/cech/general">
				<img src="../../assets/PNG/logo_cech.png" alt="" width="175px" height="120px">
			</a>
		</div>
	</div>
</div>
<!--Fin Otros Dispositivos-->
<!--Fin sitios de interés-->

<!--Sentencia-->
<!--xs-->
<div class="d-block d-sm-none">
	<div class="row sentencia">
		<div class="col-xs-12 col-sm-12">
			<h1 class="display-3 titulo text-center"><strong>LA SENTENCIA</strong></h1>
		</div>
		<div class="col-xs-12 col-sm-12 text-center">
			<a routerLink="/sentencia" style="text-decoration: none;">
				<img src="/assets/PNG/logo-sentencia.png" class="img-fluid img-sentencia" alt="">
				<h6 class="text-center"><strong>VER</strong></h6>
			</a>
		</div>
	</div>
</div>
<!--Fin xs-->
<!--md-->
<div class="d-none d-md-block d-lg-none">
	<div class="row sentencia" id="Sentencia">
		<div class="col-sm-12 col-md-12 col-lg-12">
			<h1 class="display-3 text-center titulo"><strong>LA SENTENCIA</strong></h1>
		</div>
		<div class="col-sm-12 col-md-12 col-lg-12">
			<p class="texto-sentencia text-justify">
				<br>
				El Objetivo del SÍRíoBogotá es gestionar la información referente 
				a la cuenca hidrográfica del Río Bogotá, 
				que permitirá a sus actores fortalecer la toma de decisiones, 
				la participación ciudadana y la gobernanza en la cuenca.
				<br><br>
				El módulo de sentencia comprende todo lo relacionado al cumplimento 
				y avance de las ordenes de la sentencia del río Bogotá Expediente 
				AP- 25000-23-27-000-2001-90479-1.
			</p>
		</div>
		<div class="col-md-3 offset-md-4" style="padding-top: 3rem;">
				<a routerLink="/sentencia" style="text-decoration: none;">
					<img src="/assets/PNG/logo-sentencia.png" class="img-fluid" alt="">
					<h5 class="text-center"><strong>VER</strong></h5>
				</a>
			</div>
	</div>	
</div>
<!--Fin md-->
<!--Resto de resoluciones-->
<div class="d-none d-lg-block">
	<div data-spy="scroll" data-target=".navbar" data-offset="50">
		<div class="row sentencia align-items-center">
			<div class="col-lg-6">
				<div class="col-sm-12 col-md-12 col-lg-12">
					<h1 class="display-3 text-center titulo"><strong>LA SENTENCIA</strong></h1>
				</div>
				<div class="col-sm-12 col-md-12 col-lg-12">
					<p class="texto-sentencia text-justify">
						<br>
						El Objetivo del SÍRíoBogotá es gestionar la información referente 
						a la cuenca hidrográfica del Río Bogotá,
						que permitirá a sus actores fortalecer la toma de decisiones,
						la participación ciudadana y la gobernanza en la cuenca.
						<br><br>
						El módulo de sentencia comprende todo lo relacionado al cumplimento
						y avance de las ordenes de la sentencia del río Bogotá Expediente
						AP- 25000-23-27-000-2001-90479-1.
					</p>
				</div>
			</div>
			<div class="col-lg-6 text-center">
				<a routerLink="/sentencia" style="text-decoration: none;">
					<img src="/assets/PNG/logo-sentencia.png" class="img-fluid img-sentencia" alt="">
					<h5 class="text-center"><strong>VER</strong></h5>
				</a>
			</div>
		</div>
	</div>
</div>
<!--Fin Resto resoluciones-->
<!--fin Sentencia-->

<!--Mapas-->
<!--md-->
<div class="d-none d-md-block d-lg-none">
	<div class="col-md-12">
		<h1 class="titulo-mapa text-center">CUENCA RÍO BOGOTÁ</h1>
		<p class="texto-mapa text-center">A continuación visualice la información general del río Bogotá,
			o seleccione la cuenca de su interés.</p>
	</div>
	<div class="col-md-12">
		<mat-tab-group>
			<mat-tab label="CUENCA GENERAL">
				<iframe scrolling="no"
					src="https://carcundinamarca.maps.arcgis.com/apps/webappviewer/index.html?id=50d74c693c0643be9a852cf48643dcb3"
					width="100%" height="500px" frameborder="0" class="frame-mapa"> </iframe>
			</mat-tab>
			<mat-tab label="DISTRITO">
				<iframe scrolling="no"
					src="https://eab-sigue.maps.arcgis.com/apps/webappviewer/index.html?id=6ad170bd1cdc450b823bd22d0786431d"
					width="100%" height="500px" frameborder="0" class="frame-mapa"> </iframe>
			</mat-tab>
		</mat-tab-group>
		<br>
	</div>
</div>
<!--Fin md-->
<!--Resto de resoluciones-->
<div class="d-none d-lg-block">
	<div data-spy="scroll" data-target=".navbar" data-offset="50">
		<div class="col-lg-12" id="Cuenca">
			<h1 class="titulo-mapa text-center">CUENCA RÍO BOGOTÁ</h1>
			<p class="texto-mapa text-center">A continuación visualice la información general del río Bogotá,
				o seleccione la cuenca de su interés.</p>
		</div>
		<div class="col-lg-12">
			<mat-tab-group>
				<mat-tab label="CUENCA GENERAL">
					<iframe scrolling="no"
						src="https://carcundinamarca.maps.arcgis.com/apps/webappviewer/index.html?id=50d74c693c0643be9a852cf48643dcb3"
						width="100%" height="700px" frameborder="0" class="frame-mapa"> </iframe>
				</mat-tab>
				<mat-tab label="DISTRITO">
					<iframe scrolling="no"
						src="https://eab-sigue.maps.arcgis.com/apps/webappviewer/index.html?id=6ad170bd1cdc450b823bd22d0786431d"
						width="100%" height="700px" frameborder="0" class="frame-mapa"> </iframe>
				</mat-tab>
			</mat-tab-group>
			<br>
		</div>
	</div>
</div>
<!--Fin Resto resoluciones-->
<!--Fin mapas-->

<!--Multimedia-->
<!--xs - sm-->
<div class="d-block d-sm-none">
	<div class="fondo-multimedia">
		<h2 class="text-center" style="color: #fff">EL GRAN RÍO</h2>
		<div class="col-xs-12 col-sm-12">
			<a routerLink="/multimedia/2">
				<div class="card z-depth-5 card-multimedia">
					<div class="card-header text-left header-multimedia" style="background-color: #071353;">
						<h5 class="texto-multimedia text-center">
							<i class="material-icons" style="vertical-align: middle;">play_circle_outline</i>
							VIDEOS
						</h5>
					</div>
					<div class="card-body text-center">
						<img src="../../assets/PNG/videos_galeria.PNG" class="img-fluid img-multimedia" alt="">
					</div>
				</div>
			</a>
		</div>
		<div class="col-xs-12 col-sm-12">
			<a routerLink="/multimedia/1">
				<div class="card z-depth-5 card-multimedia">
					<div class="card-header header-multimedia" style="background-color: #839D41;">
						<h5 class="texto-multimedia text-center">
							<i class="material-icons" style="vertical-align: middle;">collections</i> IMÁGENES
						</h5>
					</div>
					<div class="card-body text-center">
						<img src="../../assets/PNG/Fondo_9.jpg" class="img-fluid img-multimedia" alt="">
					</div>
				</div>
			</a>
		</div>
		<div class="col-xs-12 col-sm-12">
			<a href="https://carcundinamarca.maps.arcgis.com/apps/Cascade/index.html?appid=6376f09ad9bc43feb6c1ba582d665030"
				target="_blank">
				<div class="card z-depth-5 card-multimedia">
					<div class="card-header header-multimedia" style="background-color: #318BA7;">
						<h5 class="texto-multimedia text-center">
							<i class="material-icons" style="vertical-align: middle;">layers</i> EXPLORACIÓN
						</h5>
					</div>
					<div class="card-body text-center">
						<img src="../../assets/PNG/story_map.PNG" class="img-fluid img-multimedia" alt="">
					</div>
				</div>
			</a>
		</div>
	</div>
</div>
<!--Fin xs - sm-->
<!--md-->
<div class="d-none d-md-block d-lg-none">
	<div class="row fondo-multimedia" id="Galeria">
		<div class="col-md-12">
			<h2 class="text-center" style="color: #fff">EL GRAN RÍO</h2>
		</div>
		<div class="col-md-4">
			<a routerLink="/multimedia/2">
				<div class="card z-depth-5 card-multimedia">
					<div class="card-header text-left header-multimedia" style="background-color: #071353;">
						<h5 class="texto-multimedia text-center">
							<i class="material-icons" style="vertical-align: middle;">play_circle_outline</i>
							VIDEOS
						</h5>
					</div>
					<div class="card-body text-center">
						<img src="../../assets/PNG/videos_galeria.PNG" class="img-fluid img-multimedia" alt="">
					</div>
				</div>
			</a>
		</div>
		<div class="col-md-4">
			<a routerLink="/multimedia/1">
				<div class="card z-depth-5 card-multimedia">
					<div class="card-header header-multimedia" style="background-color: #839D41;">
						<h5 class="texto-multimedia text-center">
							<i class="material-icons" style="vertical-align: middle;">collections</i> IMÁGENES
						</h5>
					</div>
					<div class="card-body text-center">
						<img src="../../assets/PNG/Fondo_9.jpg" class="img-fluid img-multimedia" alt="">
					</div>
				</div>
			</a>
		</div>
		<div class="col-md-4">
			<a href="https://carcundinamarca.maps.arcgis.com/apps/Cascade/index.html?appid=6376f09ad9bc43feb6c1ba582d665030"
				target="_blank">
				<div class="card z-depth-5 card-multimedia">
					<div class="card-header header-multimedia" style="background-color: #318BA7;">
						<h5 class="texto-multimedia text-center">
							<i class="material-icons" style="vertical-align: middle;">layers</i> EXPLORACIÓN
						</h5>
					</div>
					<div class="card-body text-center">
						<img src="../../assets/PNG/story_map.PNG" class="img-fluid img-multimedia" alt="">
					</div>
				</div>
			</a>
		</div>
	</div>
</div>
<!--Fin md-->
<!--Resto de resoluciones-->
<div class="d-none d-lg-block">
	<div data-spy="scroll" data-target=".navbar" data-offset="50">
		<div class="row fondo-multimedia" id="Galeria">
			<div class="col-lg-12">
				<h1 class="text-center" style="color: #fff">EL GRAN RÍO</h1>
			</div>
			<div class="col-lg-4">
				<a routerLink="/multimedia/2" style="text-decoration: none;">
					<div class="card z-depth-5 card-multimedia">
						<div class="card-header text-left header-multimedia" style="background-color: #071353;">
							<h5 class="texto-multimedia text-center">
								<i class="material-icons" style="vertical-align: middle;">play_circle_outline</i>
								VIDEOS
							</h5>
						</div>
						<div class="card-body text-center">
							<img src="../../assets/PNG/videos_galeria.PNG" class="img-fluid img-multimedia" alt="">
						</div>
					</div>
				</a>
			</div>
			<div class="col-lg-4">
				<a routerLink="/multimedia/1" style="text-decoration: none;">
					<div class="card z-depth-5 card-multimedia">
						<div class="card-header header-multimedia" style="background-color: #839D41;">
							<h5 class="texto-multimedia text-center">
								<i class="material-icons" style="vertical-align: middle;">collections</i> IMÁGENES
							</h5>
						</div>
						<div class="card-body text-center">
							<img src="../../assets/PNG/Fondo_9.jpg" class="img-fluid img-multimedia" alt="">
						</div>
					</div>
				</a>
			</div>
			<div class="col-lg-4">
				<a href="https://carcundinamarca.maps.arcgis.com/apps/Cascade/index.html?appid=6376f09ad9bc43feb6c1ba582d665030"
					target="_blank" style="text-decoration: none;">
					<div class="card z-depth-5 card-multimedia">
						<div class="card-header header-multimedia" style="background-color: #318BA7;">
							<h5 class="texto-multimedia text-center">
								<i class="material-icons" style="vertical-align: middle;">layers</i> EXPLORACIÓN
							</h5>
						</div>
						<div class="card-body text-center">
							<img src="../../assets/PNG/story_map.PNG" class="img-fluid img-multimedia" alt="">
						</div>
					</div>
				</a>
			</div>
		</div>
	</div>
</div>
<!--Fin resto resoluciones-->
<!--Fin multimedia-->

<!--Noticias-->
<!--xs - sm-->
<div class="d-block d-sm-none">
	<div class="row noticias-imagen">
		<div class="col-12">
			<h2 class="text-center">NOTICIAS</h2>
		</div>
		<div class="card-deck card-size">
			<div class="col-12" *ngFor="let noticia of noticias">
				<a routerLink="/noticias/detalle/{{noticia.idNoticia}}" style="text-decoration: none; color: #656668;">
					<img class="card-img-top img-noticia" [src]="noticia?.multimediaNoticia[0]?.url" alt="Card image cap">
					<div class="card-body">
						<h6 class="card-title texto-noticia">{{ noticia.titulo}}</h6>
						<div class="card-text text-justify texto-noticia" [innerHTML]="noticia.descripcion"></div>
						<p class="card-text"><small class="text-time"><em>{{ noticia.referencia }}</em></small>
						</p>
					</div>
				</a>
			</div>
		</div>
		<mat-paginator style="background-color: transparent;" #paginator [length]="length" [pageSize]="pageSize"
			[pageSizeOptions]="pageSizeOptions" (page)="paginar($event)">
		</mat-paginator>
	</div>
</div>
<!--Fin xs - sm-->
<!--md-->
<div class="d-none d-md-block d-lg-none">
	<div class="row noticias-imagen">
		<div class="col-12">
			<h2 class="text-center">NOTICIAS</h2>
		</div>
		<div class="card-deck card-size">
			<div class="col-4" *ngFor="let noticia of noticias">
				<a routerLink="/noticias/detalle/{{noticia.idNoticia}}" style="text-decoration: none; color: #656668;">
					<img class="card-img-top img-noticia" [src]="noticia?.multimediaNoticia[0]?.url" alt="Card image cap">
					<div class="icono-mas">
						<img src="/assets/PNG/ICONO-DE-MAS-FOTOGRAFIAS.png" alt="" width="30" height="30">
					</div>
					<div class="card-body">
						<h6 class="card-title texto-noticia">{{ noticia.titulo}}</h6>
						<div class="card-text text-justify texto-noticia" [innerHTML]="noticia.descripcion"></div>
						<p class="card-text"><small class="text-time"><em>{{ noticia.referencia }}</em></small>
						</p>
					</div>
				</a>
			</div>
		</div>
		<div class="col-md-12 text-center">
			<mat-paginator style="background-color: transparent;" #paginator [length]="length" [pageSize]="pageSize"
				[pageSizeOptions]="pageSizeOptions" (page)="paginar($event)">
			</mat-paginator>
		</div>
	</div>
</div>
<!--Fin md-->
<!--Resto de resoluciones-->
<div class="d-none d-lg-block">
	<div data-spy="scroll" data-target=".navbar" data-offset="50">
		<div class="row noticias-imagen" id="Noticias">
			<div class="col-12">
				<h1 class="text-center">NOTICIAS</h1>
			</div>
			<div class="card-deck card-size">
				<div class="col-3" *ngFor="let noticia of noticias">
					<a routerLink="/noticias/detalle/{{noticia.idNoticia}}" style="text-decoration: none; color: #656668;">
						<img class="card-img-top img-noticia" [src]="noticia?.multimediaNoticia[0]?.url" alt="Card image cap">
						<div class="icono-mas">
							<img src="/assets/PNG/ICONO-DE-MAS-FOTOGRAFIAS.png" alt="" width="30" height="30">
						</div>
						<div class="card-body">
							<h6 class="card-title texto-noticia">{{ noticia.titulo}}</h6>
							<div class="card-text text-justify texto-noticia" [innerHTML]="noticia.descripcion"></div>
							<p class="card-text"><small class="text-time"><em>{{ noticia.referencia }}</em></small>
							</p>
						</div>
					</a>
				</div>
			</div>
			<div class="col-md-12 text-center">
				<mat-paginator style="background-color: transparent;" #paginator [length]="length" [pageSize]="pageSize"
					[pageSizeOptions]="pageSizeOptions" (page)="paginar($event)">
				</mat-paginator>
			</div>
		</div>
	</div>
</div>
<!--Fin resto resoluciones-->
<!--Fin Noticias-->
