import { PrincipalService } from './../../../services/principal/principal.service';
import { AppService } from "./../../../services/app.service";
import { OrdenService } from "src/app/services/orden/orden.service";
import { Component, OnInit } from "@angular/core";
import { Base } from "src/app/shared/base";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import * as XLSX from 'xlsx';
import { numbers } from '@material/menu/constants';

@Component({
  selector: "app-consulta",
  templateUrl: "./consulta.component.html",
  styleUrls: ["./consulta.component.css"],
})
export class ConsultaComponent extends Base implements OnInit {

  municipios = [];
  opcion = [];
  selectedMunicipio: number;
  selectedOrden: number;
  selectedSemestre: number;
  respuestas = [];
  respuestaById = {};
  ordenes = [];
  cargando = false;
  tipoUsuario: any;
  acceso: boolean;
  usuario: any;
  p: number = 1;
  boton: boolean;
  primer: boolean;
  segundo: boolean;
  resul = [];
  name = ' ';
  termiando: boolean;
  eleccion: any;
  ver: boolean;
  respuestaexcel = [];
  reportever: boolean;
  semestres = [];
  constructor(
    private ordenService: OrdenService,
    private appService: AppService,
    private principalService: PrincipalService,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.ver = false;
    this.selectedMunicipio = -1;
    this.selectedOrden = -1;
    this.boton = false;
    this.selectedSemestre = -1;
    this.reportever = false;
    this.usuario = sessionStorage.usuario ? JSON.parse(sessionStorage.usuario) : undefined;
    if (!this.usuario) {
      this.appService.error('No tiene permisos para ingresar a la pagina. Consulte al administrador.');
      this.acceso = false;
      return;
    }
    this.unsubscribeOndestroy(this.ordenService.isValidUser(this.usuario.usuario1)
      .subscribe(data => {
        this.tipoUsuario = data;
        this.acceso = Number(data) > 0;
        if (!this.acceso) {
          this.appService.error('No tiene permisos para ingresar a la pantalla, consulte al adminstrador');
        }
      }, err => {
        this.acceso = false;
        this.appService.error('Se produjo un error al cargar la pagina intente nuevamente');
      }));
    this.unsubscribeOndestroy(
      this.ordenService.getMunicipios().subscribe(
        (data) => (this.municipios = data),
        (err) =>
          this.appService.error(
            "Se produjo un error al obtener los municipios, intente nuevamente mas tarde."
          )
      )
    );
    this.unsubscribeOndestroy(
      this.ordenService.getOrdenes().subscribe(
        (res) => (this.ordenes = res),
        (err) =>
          this.appService.error(
            "No se pueden obtener las ordenes, intente nuevamente mas tarde"
          )
      ));
    this.unsubscribeOndestroy(
      this.ordenService.getSemestres().subscribe(
        (res) => (this.semestres = res),
        (err) =>
          this.appService.error(
            "No se pueden obtener los semestres reportados, intente nuevamente mas tarde"
          )
      ));

  }

  consultar() {
    this.cargando = true;
    this.reportever = false;

    if(this.usuario.usuario !== 'NONE' && this.selectedMunicipio === -1){
      this.selectedMunicipio = this.usuario.idMunicipio;
    }

    this.unsubscribeOndestroy(
      this.ordenService
        .getRespuestas(this.selectedSemestre, this.selectedMunicipio, this.selectedOrden, this.tipoUsuario === 1 ? 'NONE' : this.usuario.usuario1)
        .subscribe((data) => {
          this.cargando = false;
          this.respuestas = data.map(respuesta => {
            respuesta.orden = this.ordenes.find(orden => orden.idOrden === respuesta.idPreguntaNavigation.idOrden)
            return respuesta;
          })
        }, err => {
          this.appService.error('Se produjo un error al consultar las preguntas intente nuevamente.');
        })
    );
  }

  seleccionarArchivo(event, respuesta) {
    const formData = new FormData();
    formData.append(event.target.files[0].name, event.target.files[0], event.target.files[0].name);
    let orden = this.ordenes.find(orden => orden.idOrden === this.selectedOrden);
    this.unsubscribeOndestroy(
      this.principalService
        .guardarDocumento(
          formData,
          event.target.files[0].name,
          orden.idCarpetaDrive
        )
        .subscribe(
          (result: any) => {
            this.ordenService.putArchivo(event.target.files[0].name, result, respuesta.idRespuesta)
              .subscribe(res => {
                respuesta.texto = event.target.files[0].name;
                respuesta.archivo = result;
                this.appService.success('Se cargo el archivo exitosamente.');
              }, err => {
                this.appService.error('Se produjo un arror al cargar el archivo');
              });
          },
          (error) => {
            this.appService.error(
              "Se produjo un error al cargar el archivo."
            );
          }
        )
    );

  }
  openDialog(idRespuesta, idPregunta, idOpcionPregunta) {

    this.eleccion = idOpcionPregunta;
    if (!this.eleccion) {
      this.unsubscribeOndestroy(
        this.ordenService.getRespuestaById(idRespuesta)
          .subscribe(data => {
            this.respuestaById = data;
            this.dialog.open(ConfirmDialogComponent, {
              data: { dataKey: this.respuestaById }
            }).afterClosed()
            .subscribe( (data) =>{
                if(data) this.consultar()
              }
            );
          }, err => { this.appService.error('Se produjo un error al cargar el modal'); })
      );
      this.eleccion = null;
      this.primer = false;
      this.segundo = false;

    } else {
      this.unsubscribeOndestroy(
        this.ordenService.getRespuestaOpcionById(idRespuesta, idOpcionPregunta)
          .subscribe(datas => {
            this.respuestaById = datas;
            this.primer = true;
          }));
      this.unsubscribeOndestroy(
        this.ordenService.getOpcionesById(idPregunta).subscribe(
          data => {
            this.opcion = data
            this.segundo = true;
          },

        )
      );
      this.abrir();
      this.eleccion = null;

    }


  }
  abrir() {
    console.log(this.respuestaById)
    if (this.primer && this.segundo) {
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          dataKey: this.respuestaById,
          dataopci: this.opcion,
        },
      })
      .afterClosed()
      .subscribe( (data) =>{
          if(data) this.consultar()
        }
      )
    }
  }

  generarExcel(): void {
    this.cargando = true;
    this.ordenService.getgeneraterexcel(this.selectedOrden, this.selectedMunicipio, this.selectedSemestre)
    .subscribe((data) => {
      this.boton=true;
      this.respuestaexcel = data.map(respu => {
        respu.orden = this.resul.find(orden => orden.municipio === respu.municipio);
        return respu
      });
      setTimeout(() => {
        this.name = `Información_Financiera_del_municipio_${this.selectedMunicipio}_con_orden_${this.selectedOrden}.xlsx`;
        let element = document.getElementById('generarexcel');
        const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
        const book: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(book, worksheet, 'Sheet1');
        XLSX.writeFile(book, this.name);
        this.cargando = false;
      }, 2000);

    }, err => {
      this.cargando = false;
      this.appService.error('Se produjo un error al generar el Excel intente nuevamente.');
    },)
  }

}
