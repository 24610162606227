<nav class="navbar-size z-depth-5">
	<div class="nav-wrapper">
		<div class="card-img-overlay" style="margin-top: -1%; z-index: 1">
			<a routerLink="/home" class="brand-logo center">
				<img src="../../assets/PNG/logo_sistema.png" alt="" class="img-size img-fluid">
			</a>
		</div>
		<div class="container col-sm-12 col-md-12 col-lg-12 navs-left">
			<ul id="nav-mobile" class="left hide-on-med-and-down">
				<li><a class="navbar-options" href="#Sentencia"><strong>Sentencia</strong></a></li>
				<li><a class="navbar-options" href="#Cuenca"><strong>Cuenca</strong></a></li>
				<li><a class="navbar-options" href="#Galeria"><strong>Galería</strong></a></li>
			</ul>
		</div>
		<div class="container col-sm-12 col-md-12 col-lg-12 navs-right">
			<ul id="nav-mobile" class="right hide-on-med-and-down">
				<li><a class="navbar-options" href="#Noticias"><strong>Noticias</strong></a></li>
				<li><a class="navbar-options" href="#entidades"><strong>CECH</strong></a></li>
				<!--<li><a class="navbar-options" routerLink="/site/map"><strong>Sitemap</strong></a></li>-->
			</ul>
		</div>
	</div>
	<div class="col-sm-12 col-md-12 col-lg-12 center-align" style="z-index: 0">
		<img src="../../assets/PNG/borde-inferior.png" alt="" class="img-fluid border-size">
	</div>
</nav>