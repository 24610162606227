<div *ngIf="id == '1'">
  <div class="container">
    <h1 class="font-weight-light text-center text-lg-left mt-4 mb-0">Galería de Imágenes</h1>    
    <hr class="mt-2 mb-5">    
    <div class="row text-center text-lg-left">    
      <div class="col-lg-3 col-md-4 col-6" *ngFor="let imagen of imagenes">
        <a (click)="openDialog(imagen.url)" class="d-block mb-4 h-100">
          <img class="img-fluid img-thumbnail" [src]="imagen.url" alt="" height="10rem" width="20rem">
        </a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="id === '2'" class="row">
  <div class="container">
    <h1 class="font-weight-light text-center text-lg-left mt-4 mb-0">Galería de Videos</h1>
    <hr class="mt-2 mb-5">
    <div class="row text-center text-lg-left">
      <div class="col-md-4 col-lg-3 col-6 pb-video" *ngFor="let video of videos">
        <iframe class="pb-video-frame" width="100%" height="230" [src]="video.url" frameborder="0" allowfullscreen></iframe>              
      </div>    
    </div>
  </div>
</div>  
