<h4>Consultar Usuarios</h4>
<div class="input-group mb-3" style="display: inline-flex;">
  <span style="width: 25rem; margin-right: 3rem;">
    <input type="text" placeholder="Filtrar por nombre" class="form-control" [(ngModel)]="filtro" (input)="filtrar()">
  </span>
  <span style="margin-right: 2rem;">
    <mat-form-field>
      <mat-select placeholder="Filtrar por rol" [(ngModel)]="selectedFilterRol" multiple>
        <mat-option *ngFor="let rol of roles" [value]="rol[0].nombre">
          {{rol[0].nombre}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </span>
  <span>
    <mat-form-field>
      <mat-select placeholder="Filtrar por entidad" [(ngModel)]="selectedFilterEntidad" multiple>
        <mat-option *ngFor="let entidad of entidades" [value]="entidad.descripcion">
          {{entidad.descripcion}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </span>
</div>
<div>
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Nombre de usuario</th>
        <th scope="col">Primer Nombre</th>
        <th scope="col">Primer Apellido</th>
        <th scope="col">Rol</th>
        <th scope="col">Entidad</th>
        <th scope="col">Accion</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let usuario of usuarios">
        <th scope="row">{{usuario.usuario1}}</th>
        <td>{{usuario.primerNombre}}</td>
        <td>{{usuario.primerApellido}}</td>
        <td>{{usuario.rolUsuario[0]?.idRolNavigation?.nombre}}</td>
        <td>{{usuario.idEntidadNavigation?.descripcion}}</td>
        <td><a routerLink="/usuario/{{usuario.idUsuario}}" class="editar">Editar</a> &nbsp;<span class="eliminar"
            (click)="eliminar(usuario.idUsuario)">Eliminar</span></td>
      </tr>
    </tbody>
  </table>
</div>