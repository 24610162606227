<div class="center">
  <mat-spinner *ngIf="cargando"></mat-spinner>
</div>
<div style="height: 13rem;" *ngIf="acceso">
  <span style="margin-right: 2rem">
    <mat-form-field>
      <mat-select placeholder="seleccione una orden" (selectionChange)="consultarPreguntas($event.value)"
        [(ngModel)]="selectedOrden">
        <mat-option *ngFor="let orden of ordenes" [value]="orden.idOrden" >
          {{ orden.nombre }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field >
      <mat-select  placeholder="seleccione un semestre" [(ngModel)]="selectedSemestre">
        <mat-option  [value]="-1"> Semestres reportados </mat-option>
        <mat-option  *ngFor="let semestre of semestres"  [value]="semestre.idOpcion">
          {{ semestre.descripcion }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="seleccione un tipo de grafica" [(ngModel)]="selectedGrafica">
        <mat-option [value]="1">
          Grafica de barras
        </mat-option>
        <mat-option [value]="2">
          Grafica de pastel
        </mat-option>
      </mat-select>
    </mat-form-field>
  </span>
  <div>
    <mat-form-field style="width: 100%;" *ngIf="preguntas && preguntas.length">
      <mat-select placeholder="seleccione una pregunta" [(ngModel)]="selectedPregunta">
        <mat-option *ngFor="let pregunta of preguntas" [value]="pregunta.idPregunta">
          {{ pregunta.descripcion }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <button (click)="consultar()" [disabled]="!preguntas || !preguntas.length" style="margin-left: 1rem"
      class="btn btn-default">
      Ver grafica
    </button>
  </div>
  <div>
  </div>
</div>
<div class="grafic-container" *ngIf="showGrafica">
  <div *ngIf="selectedGrafica == 1">
    <ngx-charts-bar-vertical *ngIf="selectedGrafica == 1" [view]="view" [results]="datos" 
    [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="false" [showXAxisLabel]="showXAxisLabel" [tooltipDisabled]="true"
    [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" (select)="onSelect($event)">
  </ngx-charts-bar-vertical>
  <div  *ngFor="let data of datos">
    <h5>{{data.name}} : {{data.value}}</h5>

  </div>
  </div>
  <div *ngIf="selectedGrafica == 2">
    <ngx-charts-advanced-pie-chart *ngIf="selectedGrafica == 2" [view]="view" [results]="datos" [gradient]="true"
    [legend]="true" [legendPosition]="legendPosition" [labels]="true" [doughnut]="isDoughnut"
    (select)="onSelect($event)" >
  </ngx-charts-advanced-pie-chart>
  </div>
</div>