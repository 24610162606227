import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppModuleShared } from './app.shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './auth/token-interceptor.service';
import { HttpClientModule } from '@angular/common/http';
import { AppService } from './services/app.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material/sidenav';

@NgModule({

  declarations: [],
  imports: [
    AppModuleShared,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSidenavModule,
  ],
  providers: [{ provide: 'BASE_URL', useFactory: getBaseUrl }, {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  AppService
],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function getBaseUrl() {
    return 'https://siriobogotaapi.car.gov.co:5000/';
    // return 'https://apim-modernizacion-dev.azure-api.net/riobogota/';
    //return 'http://localhost:64513/';
}
