<div [formGroup]="form">
  <div>
    <span class="errorMessage" *ngIf="hasErrors?.required">* Campo requerido </span>
    <span class="errorMessage" *ngIf="hasErrors?.maxlength">Maxima longitud permitida {{question.max}} caracteres</span>
    <span class="errorMessage" *ngIf="hasErrors?.minlength">Minima longitud permitida {{question.minlength}} caracteres</span>
    <label [attr.for]="question.key">{{ question.label }}</label>
  </div>

  <div>
    <input
      *ngIf="question.controlType === 'textbox' && question.type !== 'file'"
      class="form-control"
      [formControlName]="question.key"
      [id]="question.key"
      [type]="question.type"
    />
    <input
    *ngIf="question.controlType ==='textbox'  && question.type === 'file'"
    class="form-control"
    [formControlName]="question.key"
    (change)="cargarArchivo($event)"
    [id]="question.key"
    [type]="question.type"
  />
    <select
      class="form-control"
      *ngIf="question.controlType ==='dropdown'"
      [id]="question.key"
      [formControlName]="question.key"
    >
      <option
        *ngFor="let opt of question.options"
        [ngValue]="opt.idOpcionNavigation.idOpcion"
      >
        {{ opt.idOpcionNavigation.descripcion }}
      </option>
    </select>
  </div>
</div>
