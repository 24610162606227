
<div *ngIf="text">
    <h1 mat-dialog-title >
        {{datos.idPreguntaNavigation.descripcion}}</h1>
    <div mat-dialog-content>
        <form name="formDialog" ngNativeValidate>
          <input  type="text" required class="form-control"  aria-label="Titulo" name="titulo_modal"
          [(ngModel)]="datos.texto" aria-describedby="usuario-default">
        </form>
    </div>
</div>
<div *ngIf="fechadatos">
    <h1 mat-dialog-title >{{datos.idPreguntaNavigation.descripcion}}</h1>
    <div mat-dialog-content>
        <form name="formDialog" ngNativeValidate>
            <input type="date" required class="form-control"  aria-label="Fecha" name="fechaPublicacion"
              [(ngModel)]="datos.fecha"  [value]="datos.fecha" (ngModelChange)="datos.fecha = $event" aria-describedby="usuario-default" >
        </form>
    </div>
</div>
<div *ngIf="moneda">
    <h1 mat-dialog-title>
        {{datos.idPreguntaNavigation.descripcion}}</h1>
       <div mat-dialog-content>
           <form name="formDialog" ngNativeValidate>
               <input type="number" required class="form-control" minlength="5" aria-label="Titulo" name="moneda_modal"
               [(ngModel)]="datos.moneda" aria-describedby="usuario-default">
           </form>
       </div>
</div>
<div *ngIf="number">
    <h1 mat-dialog-title >{{datos.idPreguntaNavigation.descripcion}}</h1>
    <div mat-dialog-content>
        <form name="formDialog" ngNativeValidate>
            <input type="number" required class="form-control" minlength="5" aria-label="Number" name="number_modal"
            [(ngModel)]="datos.numero" aria-describedby="usuario-default">
        </form>
    </div>
</div>
<div *ngIf="select">
    <h1 mat-dialog-title >{{datos.idPreguntaNavigation.descripcion}}</h1>
    <div mat-dialog-content >
        <form name="formDialog" ngNativeValidate>
            <mat-form-field>
                <mat-select  [(ngModel)]="datos.idOpcionPregunta" name="opciones" id ="opciones">
                <mat-option [value]="op.idOpcion" *ngFor="let op of opciones">{{op.descripcion}}</mat-option>
                </mat-select>
               </mat-form-field>
        </form>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="RealizarUpdate()">Actualizar</button>
    <button mat-button  (click)="onClick()">Cerrar</button>

</div>
