export class ENUMTipoNoticia {

}
export enum TipoNoticia {
    PRINCIPAL = 1,
    SECUNDARIA = 2,
    INTERES = 3,
    ULTIMO = 4,
    DESTACADO = 5,
    EVENTOS = 6
}