<!--Resto de resoluciones-->
<div class="d-none d-lg-block container-noticias">
	<div class="row">
		<div class="col-12">
			<h2 class="text-center">Documentos Sentencia</h2>
		</div>
	</div>
	<div class="row">
		<div class="col 6">
			<p class="text-justify">
				El objetivo de Sí Río Bogotá es divulgar y gestionar el conocimiento del estado socioambiental, el
				seguimiento del cumplimiento de las órdenes de la sentencia y la gestión integral de la cuenca
				hidrográfica del río Bogotá, a través de la articulación interinstitucional y la participación de los
				diferentes agentes sociales. La información del Sí Río Bogotá permitirá la definición de políticas
				públicas; la administración de recursos e inversiones y la planificación de proyectos, para mejorar las
				condiciones de vida de sus habitantes, la protección de la naturaleza y disfrutar de un medio ambiente
				sano que se adapte a la variabilidad y el cambio climático.
			</p>
		</div>
		<div class="col 6 text-center">
			<a href="../../assets/resumen_Sentencia.pdf" target="_blank">Descargar Objetivo del Sistema <mat-icon>
					save_alt</mat-icon></a>
			<hr>
			<a href="../../assets/sentencia_rio_bogota.pdf" target="_blank">Descargar Sentencia completa <mat-icon>
					save_alt</mat-icon></a>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12 col-lg-12">
			<pdf-viewer [src]="pdfSrc" [render-text]="true"
				style="display: block; height: 600px; overflow-y: scroll; overflow-x: visible;">
			</pdf-viewer>
		</div>
	</div>
</div>
	