export class Contacto {
    idContacto: number;
    primerNombre: string;
    segundoNombre: string;
    primerApellido: string;
    segundoApellido: string;
    telefono: string;
    celular: string;
    correo: string;
    idTipoContacto: number;
    descripcion: string;
    fecha: Date;
}