<div class="d-block" *ngIf="facebook?.transmitiendo && router.url && (router.url === '/home' || 
router.url === '/home#Cuenca' || router.url === '/home#Componentes' || 
router.url === '/home#Noticias' || router.url === '/home#Sentencia' || 
router.url === '/home#Entidades' || router.url === '/home#Galeria')">
  <div class="row text-center">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="facebook-title-container">
        <div class="emitiendo">
        </div>
        <div class="facebook-title">Facebook Live</div>
      </div>
      <div class="facebook-container">
        <div>
          <iframe [src]="facebook.urlVideo" width="900" height="500" style="border:none;overflow:hidden" scrolling="no"
            frameborder="0" allowTransparency="true" allowFullScreen="true"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Componentes-->
<div class="row justify-content-center" style="margin-top: -5rem;" *ngIf="!facebook?.transmitiendo && router.url && (router.url === '/home' || router.url === '/home#Cuenca' || 
  router.url === '/home#Componentes' || router.url === '/home#Noticias' || 
  router.url === '/home#Sentencia' || router.url === '/home#Entidades' || router.url === '/home#Galeria')">
  <div *ngIf="urlImagen" class="img-principal">
    <img [src]="urlImagen" alt="" class="img-fluid" width="100%" height="731.8px">
    <h1 class="card-img-overlay texto-principal">
      <strong [innerHtml]="soporte.textoInicio" class="blue-title">
      </strong>
    </h1>
  </div>
  <div *ngIf="!urlImagen" class="img-principal">
    <img src="../../assets/PNG/cuenca_PTAR_salitre.jpg" alt="" class="img-fluid" width="100%" height="100%">
    <h1 class="card-img-overlay texto-principal">
    </h1>
  </div>
</div>
<!--Fin Componentes-->
<!-- tarjetas -->
<div *ngIf="router.url && (router.url === '/home' || router.url === '/home#Cuenca' || 
router.url === '/home#Componentes' || router.url === '/home#Noticias' || 
router.url === '/home#Sentencia' || router.url === '/home#Entidades' || router.url === '/home#Galeria')"
  class="row tarjetas-transmitiendo iconos-front text-center" style="margin-top: 0; margin-bottom: 6rem;">
  <a routerLink="/unica/1" class="iconos-componentes hover01" style="height: 45px;">
    <figure style="border: 1px #0FBEE5 solid;">
      <img src="../../assets/PNG/ambiental.png" alt="" class="img-thumbnail iconos-componentes">
    </figure>
  </a>
  <a routerLink="/unica/2" class="iconos-componentes hover01" style="height: 45px;">
    <figure style="border: 1px #0FBEE5 solid;">
      <img src="../../assets/PNG/financiero.png" alt="" class="img-thumbnail iconos-componentes">
    </figure>
  </a>
  <a href="http://www.orarbo.gov.co/" target="_blank" class="iconos-componentes hover01" style="height: 45px;">
    <figure style="border: 1px #0FBEE5 solid;">
      <img src="../../assets/PNG/educacion.png" alt="" class="img-thumbnail iconos-componentes">
    </figure>
  </a>
  <a routerLink="/unica/4" class="iconos-componentes hover01" style="height: 45px;">
    <figure style="border: 1px #0FBEE5 solid;">
      <img src="../../assets/PNG/politico.png" alt="" class="img-thumbnail iconos-componentes">
    </figure>
  </a>
  <a routerLink="/unica/5" class="iconos-componentes hover01" style="height: 45px;">
    <figure style="border: 1px #0FBEE5 solid;">
      <img src="../../assets/PNG/proyectos.png" alt="" class="img-thumbnail iconos-componentes">
    </figure>
  </a>
  <a class="iconos-componentes hover01" style="height: 45px;">
    <figure style="border: 1px #0FBEE5 solid;">
      <img src="../../assets/PNG/indicadores.png" alt="" class="img-thumbnail iconos-componentes">
    </figure>
  </a>
</div>
<!-- fin tarjetas -->